/* eslint-disable */
<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          Select a document:
        </slot>
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

			<section class="modal-body">
				<slot name="body">
					<div v-if="mydocumentsList.length > 0">
						<div class="doc-list" v-for="doc in mydocumentsList" v-bind:key="doc"> 
							<a @click="loadDocument(doc)" class="list-link">{{formatDisplay(doc)}}</a>
							<a @click="deleteDocument(doc)" class="delete_icon" title="Delete">x</a>
						</div>
					</div>
					<div v-else>
						No Documents
					</div>
				</slot>
			</section>

      <footer class="modal-footer">
        <button
          type="button"
          class="btn-green"
          @click="close"
        >
          Close
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
	/* eslint-disable */	
	import CookieHelper from "@/helpers/cookie-helper";

  export default {
    name: 'modal-documents',
	  data() {
	    return {
	      mydocumentsList: [],
	    } 
	  },
	  async created() {
	  	this.listDocuments();
	  },
	  async mounted() {
	  },
    methods: {
      close() {
        this.$emit('close');
      },
      formatDisplay(doc){
        let full_text = doc;
        let left_string = '';
        let right_string = '';
        if (doc.length>=25){
            left_string = full_text.substr(0,10);
            right_string = full_text.substr(full_text.length - 10);
            full_text = left_string + '...' + right_string;
        }
        return full_text;
      },
		  listDocuments(){
		    let myDocuments = CookieHelper.getFlag("documents", "");
		    if (myDocuments!=''){
		    	let arr_mydocuments = JSON.parse(myDocuments);
		    	this.mydocumentsList = arr_mydocuments; 
		    }
		  },
		  loadDocument(doc){
		  	this.$emit('loadDocument',doc);
		  },
		  deleteDocument(doc){
		  	this.$emit('deleteDocument',doc);
		  }
    },
  };
</script>

<style scoped>
	.doc-list {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
	.doc-list a{
		cursor: pointer;
		color: #1677ff;
	}
	.list-link{
		white-space: nowrap; 
		width: 450px; 
		overflow: hidden;
		display: inline-block;
	}
	@media only screen and (max-width: 1100px) {
		.list-link{
			width: 400px; 
		}
	}
	@media only screen and (max-width: 980px) {
		.list-link{
			width: 300px; 
		}
	}
	@media only screen and (max-width: 550px) {
		.list-link{
			width: 250px; 
		}
	}
	@media only screen and (max-width: 400px) {
		.list-link{
			width: 200px; 
		}
	}
	@media only screen and (max-width: 350px) {
		.list-link{
			width: 150px; 
		}
	}

	.btn_icon {
    margin-left: 10px;
    width: 15px;;
		vertical-align: top;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 5px;
  }
	@media only screen and (max-width: 700px) {
	  .modal {
	    width: 90%;
	  }
	}

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #1677ff;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 40px 20px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #1677ff;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #1677ff;
    border: 1px solid #1677ff;
    border-radius: 5px;
    padding: 10px;
  }

  .delete_icon {
    font-size: 14px;
    color: red !important;
    font-weight: 700;
    margin-left: 10px;  
		vertical-align: text-top;
  }
</style>