<template>
  <div id="app">
    <HomePage/>
  </div>
</template>

<script>
import HomePage from './components/UploadFile.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,400;0,500;1,400&display=swap');

html {
  /*background: rgb(84,155,240) !important;
  background: linear-gradient(90deg, rgba(84,155,240,1) 24%, rgba(86,12,189,1) 57%, rgba(87,156,237,1) 100%) !important;
  margin: 0px;*/
}  
html, body {
  height: 97%;
  margin: 0px;
}
#app {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 93%;
}
</style>
