/* eslint-disable */
<template>
  <div class="modal-backdrop delete-modal">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          Are you sure you want to delete this document?
        </slot>
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

			<section class="modal-body">
        <div v-html="docToDelete" class="doc-to-delete"></div>
			</section>

      <footer class="modal-footer">
        <div class="button-div">
            <button
            type="button"
            class="btn-red"
            @click="deleteDocument"
          >
            Yes
          </button>

          <button
            type="button"
            class="btn-gray"
            @click="close"
          >
            Cancel
          </button>
        </div>
        <div style="clear: both;"></div>
      </footer>
    </div>
  </div>
</template>

<script>
	/* eslint-disable */	
	import CookieHelper from "@/helpers/cookie-helper";

  export default {
    name: 'modal-delete',
    props: ['docToDelete'],
    
	  async created() {
	  },
	  async mounted() {
	  },
    methods: {
      close() {
        this.$emit('close');
      },
		  deleteDocument(){
        this.$emit('confirmDeleteDocument',this.docToDelete);
		  }
    },
  };
</script>

<style scoped>
  .doc-to-delete {
    word-break: break-all;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 5px;
  }
	@media only screen and (max-width: 700px) {
	  .modal {
	    width: 90%;
	  }
	}

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #1677ff;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 40px 20px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #1677ff;
    background: transparent;
  }

  .button-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .btn-red {
    color: white;
    background: red;
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    text-align: center;
    min-width: 67px;
    font-weight: bold;
		font-size: 14px;
  }

  .btn-gray {
    color: #fff;
    background: #afaeae;
    border: 1px solid #afaeae;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
    text-align: center;
    min-width: 65px;
    font-weight: bold;
		font-size: 14px;
  }

</style>